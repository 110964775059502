
.logo {
      grid-column: 1/2;
      align-self: center;

      svg {
          height: 42px;
          width: auto;
      }
}




