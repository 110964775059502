.ModalWrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    //position: fixed;
    //top: 0;
    //left: 0;
    z-index: 99;
    display: flex;
    backdrop-filter: blur(10px);
}
.Modal {
    background: white;
    margin: auto;
    position: relative;
    z-index: 99;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    &.off {
        opacity: 0;
        visibility: hidden;
        filter: blur(8px);
        transform: scale(0.33);
        box-shadow: 1rem 0 0 rgba(black, 0.2);
    }
    @media (prefers-reduced-motion) {
        offset-path: none;
    }
    h1 {
        border-bottom: 1px solid #ccc;
        padding: 1rem;
        margin: 0;
        text-align: center;
        color : #0066BB;
        filter: drop-shadow(5px 5px 5px gray);
    }
    input {
        //background-color: d;
        margin: 10px;
        margin-bottom: 0px;
        padding : 6px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        border: 1px solid gray;
        border-radius: 5px;
    }
    img {
        border: 2px dashed gray;
        border-radius: 10px;
        margin: 20px;
        background-color: rgb(222,222,222);
    }
    .content {
        padding: 1rem;
    }
    .actions {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #ccc;
        background: #eee;
        padding: 2rem 2rem;
    }
}
.toggle-button {
    border: 2px solid green;
    background: #78f89f;
    border-radius: 20px;
    padding: 1rem 1rem;
    font-size: 2rem;
    font-weight: bold;
    color: #02270d;
    line-height: 1;
    box-shadow: 4px 4px 16px rgba(black, 0.2);
}
.itemImage {
    margin : auto;
    width : 100%;
    height : 100%;
    
    max-width: 100%;
    max-height: 100%;
    display: block; /* remove extra space below image */

    border: 1px solid #AACCFF;
    border-radius: 5px;
    
}
.itemContainer {
    display : flex;
    flex-wrap: wrap;
    width : 80%;
    margin: auto;
    background-color: white;
    padding : 20px;
    margin-bottom: 100px;
}

CircularProgress {
    background-color: black;
}